.whiteTowers{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 100px;
    margin-right: 70px;
}
.under-reconstruction{
    width: 100%;
    height: 100%;
}

.under-reconstruction h1{ 
    font-weight: 700;
}

.under-reconstruction p{
    font-family: 'BPG Nino Medium';
    font-size: 20px;
}

.alert-text{
    margin-top: 100px;
    margin-left: 100px;
}