.footer-container{
    background-color: #10202b;
    color:white;
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0 !important;
    font-size: 16px;
}
.bpms{
    margin-left:  2px;
    margin-right: 2px;
}
/* ---------- responsive part ------------ */
@media screen and (max-width: 1100px){

    .footer-container{
        height: 6%;
        font-size: 14px;
    }

}
@media screen and (max-width: 900px){

    .footer-container{
        height: 6%;
        font-size: 13px;
    }

}

