
.name1{
    font-size: 16px;
    gap: 10px;
}

.home-component{
    height: 100vh;
    /* background-image: url('../../assets/backgr.png'); */
    background-size:60% 87%;
    background-repeat: no-repeat;
    background-position: 0px 70px ;
    background-size: contain;
}
.left-home-component{
    width: 50%;
    height: 100%;
    overflow: hidden;
}
.left-home-component img{
    width: 100%;
    height: 100%;
}
.middle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    color: white;
    background-color: #DAA161;
    width: 50%;
    height: 100%;
    padding-top: 200px;
    box-sizing: border-box;
    padding-right: 50px;
   
}

.middle-container{
    height: 83%;
    width: 100%;
    display: flex;
   
}
.middle-container *{
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.renting{
    position: relative !important;
    right: 0 !important;
    top:0 !important;
}



.h1en_cap{
    font-size: 53px;
    word-spacing: 2px;
    text-transform: uppercase;
}
.h1en{
    font-size: 53px;
    word-spacing: 2px;
}
.h2en_cap{
    font-size: 35px;
    word-spacing: 1px;
    text-transform: uppercase;
}
.h2en{
    font-size: 35px;
    word-spacing: 1px;
}

.p2en{
    font-size: 24px;
    line-height: 30px;
    word-spacing: 2px;
    letter-spacing: 1px;
}
.p2en_cap{
    font-size: 24px;
    line-height: 30px;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.p1en{
    font-size: 21px;
    line-height: 15px;
    word-spacing: 2px;
    letter-spacing: 1px;
}
.p1en_cap{
    font-size: 21px;
    line-height: 15px;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.p3en_cap{
    font-size: 23px;
    line-height: 20px;
    word-spacing: 2px;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.p3en{
    font-size: 30px;
    word-spacing: 2px;
    letter-spacing: 0px;
}


.company-name{
    font-size:43px;
    /* animation: slideUp 1s;
    margin-bottom: 30px; */
}
.into{
    width: 90%;
    text-align: right;
    font-family: 'BPG Nino Medium';
    font-size: 25px;
    /* animation: slideUp 2s;
    margin-bottom: 30px; */
}
.more{
    font-family: 'BPG Nino Medium';
    font-size: 18px;
    margin-top: -15px;
    margin-bottom: 35px;
    /* animation: slideUp 3s;
    margin-bottom: 30px; */
}
.organizational-structure-button{
    background: white;
    /* border: 1px solid #FFFFFF; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color:#10202B !important;
    font-size: 21px;
    padding: 20px 40px;
    position: absolute;
    right: 100px;
    top:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: none !important;
    /* animation: slideUp 4s;
    margin-bottom: 30px; */
}
/* @keyframes slideUp{
    from{
        margin-bottom: -30px;
        color: transparent;
    }
    to{
        margin-bottom: 30px;
       color:white;
    }
} */


/* ------------------------ Responsivity ---------------- */

@media screen and (max-width: 1400px) {
    .company-name{
        font-size:40px;
    }
    .into{
        width: 95%;
        font-size: 20px;
    }
    .organizational-structure-button{
        background: white;
        font-size: 18px;
        padding: 15px 30px;
        right: 100px;
        top:200px;
        
    }
    .more{
        font-size: 18px;
        margin-top: -15px;
        margin-bottom: 35px;
    }
    .left-home-component{
        width: 50%;
        height: 100%;
        
    }
    .middle{
        width: 50%;
        height: 100%;
        padding-top: 200px;
        box-sizing: border-box;
        padding-right: 50px;
       
    }
    .middle-container{
        height: 85%;
    }
  }


@media screen and (max-width: 1100px) {
    .company-name{
        font-size:40px;
    }
    .into{
        width: 95%;
        font-size: 20px;
    }
    .organizational-structure-button{
        background: white;
        font-size: 18px;
        padding: 9px 16px;
        right: 100px;
        top:200px;
        
    }
    .more{
        font-size: 16px;
        margin-top: -15px;
        margin-bottom: 35px;
    }
    
    .middle{
        width: 50%;
        height: 100%;
        padding-top: 150px;
        box-sizing: border-box;
        padding-right: 20px;
       
    }
    .middle-container{
        height: 85%;
    }
    
  }


@media screen and (max-width: 980px) {
    .middle-container{
        height: 85%;
       
       
    }
    .company-name{
        font-size:35px;
    }
    .into{
        width: 95%;
        font-size: 20px;
    }
    .organizational-structure-button{
        background: #DAA161;
        font-size: 16px;
        border: 1px solid #FFFFFF;
        padding: 15px 30px;
        right: 100px;
        top:200px;
        
    }
    .more{
        font-size: 15px;
    }
    .left-home-component{
        width: 100%;
        height: 100%;
        
    }
    .middle{
        width: 80%;
        height: 100%;
        padding-top: 150px;
        box-sizing: border-box;
        padding-right: 25px;
        background-color: transparent;
        position: absolute;
        top:0;
        right: 0;
       
    }
    .name1{
        font-size: 12px;
        gap: 10px;
    }
    
  }


@media screen and (max-width: 590px) {
    .company-name{
        font-size:35px;
    }
    .into{
        width: 100%;
        font-size: 18px;
    }
    .organizational-structure-button{
        background: #DAA161;
        font-size: 16px;
        border: 1px solid white;
        padding: 10px 16px;
        right: 100px;
        top:200px;
        
    }
    .more{
        font-size: 15px;
    }
    .left-home-component{
        width: 100%;
        height: 100%;
        
    }
    .middle{
        width: 90%;
        height: 100%;
        padding-top: 150px;
        box-sizing: border-box;
        padding-right: 25px;
        background-color: transparent;
        position: absolute;
        top:0;
        right: 0;
       
    }
    .name1{
        font-size: 12px;
        gap: 10px;
    }
    .middle-container{
        height: 85%;
    }
  }


@media screen and (max-width: 500px) {
    .company-name{
        font-size:35px;
    }
    .into{
        width: 100%;
        font-size: 18px;
    }
    .organizational-structure-button{
        background: #DAA161;
        font-size: 15px;
        border: 1px solid white;
        padding: 6px 12px;
        right: 100px;
        top:200px;
        
    }
    .more{
        font-size: 15px;
    }
    .left-home-component{
        width: 100%;
        height: 100%;
        
    }
    .middle{
        width: 97%;
        height: 100%;
        padding-top: 150px;
        box-sizing: border-box;
        padding-right: 10px;
        background-color: transparent;
        position: absolute;
        top:0;
        right: 0;
       
    }
    .name1{
        font-size: 12px;
        gap: 10px;
    }
    .middle-container{
        height: 85%;
    }
    .left-home-component img{
        width: 130%;
        height: 100%;
        object-position: -50px;
        
    }
  }


@media screen and (max-width: 400px) {
   
    .organizational-structure-button{
        
        font-size: 14px;
        padding: 6px 12px;
        
    }
  }


  @media screen and (max-width: 360px) {
   
    .organizational-structure-button{
        
        font-size: 13px;
        
        
    }
  }  