.about-left{
    width: 60%;
    margin-top: 100px;
    margin-left: 100px;
    line-height: 25px;
}
.about-left p{
    margin-top: 30px;
    font-family: 'BPG Nino Medium';  
    font-size: 20px;  
}

.whiteTowers{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px !important;
    height: auto;
    margin-right: 40px !important;
    margin-bottom: 50px;
}


.organizational-structure-button:hover{
    background: rgb(236, 234, 234);
}

.pdf-container{
    display: flex;
}

.pdf-container img{
margin-right:20px;
}

.blackLink{
    color:#10202B;
}

.p3en{
    font-size: 25px !important;
    word-spacing: 2px;
    letter-spacing: 1px;
}

.p_en{
    font-size: 25px !important;
    word-spacing: 2px;
    letter-spacing: 1px;
    line-height: 50px;
}
.p22en_cap{
    font-size: 21px !important;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: none;
}
.organizational-structure-button2:hover{
    background:#c28f56;
}

.organizational-structure-button2{
    background: #DAA161;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color:white !important;
    font-size: 21px;
    padding: 20px 40px;
    position: absolute;
    right: 100px;
    top:140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: none !important;
  
}

.aboutInfo_ge{
    font-size: 19px;
}
.aboutInfo_en{
    font-size: 24px !important;
    line-height: 33px;
}

.pdf_ge{
font-size: 18px !important;
}
.pdf_en{
    font-size: 23px !important;
}

.about-container{
    padding-bottom: 100px;
}
/* ------------------------ Responsivity ---------------- */

@media screen and (max-width: 1400px) {
    .organizational-structure-button2{
        font-size: 19px;
        padding: 20px 40px;
        right: 50px;
        /* top:160px;       */
        background-color: #DAA161;
        
    }
    .whiteTowers{       
      width: 29% !important;
      margin-right: 30px !important;
      margin-bottom: 90px !important;
    }
    .about-left h1{

    }
    .aboutInfo_en{
        font-size: 15x !important;
    }
    .aboutInfo_ge{
        font-size: 16px !important;
    }
    .pdf_ge{
        font-size: 17px !important;
    }
    .pdf_en{
        font-size: 18px !important;
    }
  }


@media screen and (max-width: 1100px) {
    .organizational-structure-button2{
        font-size: 18px;
        padding: 17px 34px;
        right: 100px;
        background-color: #DAA161;
    }
    .whiteTowers{       
      width: 33% !important;
      margin-right: 20px !important;
      margin-bottom: 30px !important;
    }
    .about-left{
        width: 50%;
        margin-top: 100px;
        margin-left: 100px;
        line-height: 25px;
    }
    .pdf-container img{
       width: 35px;
       height: auto;
        }
    .about-left h1{

    }
      .aboutInfo_en{
        font-size: 20px !important;
    }
    .aboutInfo_ge{
        font-size: 16px !important;
    }
    .pdf_ge{
        font-size: 17px !important;
    }
    .pdf_en{
        font-size: 17px !important;
    }
    
  }


@media screen and (max-width: 920px) {
    .organizational-structure-button2{
        font-size: 16px !important;
        left:30px;
        background-color: #DAA161;
        top:180px;
    }
    .whiteTowers{       
      width: 40% !important;
      margin-right: 20px !important;
      margin-bottom: 0px !important;
     bottom: 10px !important;
     margin-top: 50px;
    }
    .about-left{
        width: 80%;
        margin-top: 100px;
        margin-left: 30px;
        line-height: 25px;
    }
    .pdf-container img{
       width: 35px;
       height: auto;
    
        }
    .about-left h1{

    }
    .aboutInfo_en{
        margin-top: 70px !important;
        font-size: 20px !important;
    }
    .aboutInfo_ge{
        margin-top: 70px !important;
        font-size: 16px !important;
    }
    .pdf_ge{
        font-size: 17px !important;
    }
    .pdf_en{
        font-size: 17px !important;
    }
    
    .about-container{
        padding-bottom: 40px;
    }
  }


@media screen and (max-width: 590px) {
    
  }


@media screen and (max-width: 500px) {
    
  }


@media screen and (max-width: 400px) {
    
  }


  @media screen and (max-width: 360px) {
        
    }
  