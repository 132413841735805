@font-face {
    font-family: 'BPG Nino Mtavruli';
    src: url('./assets/bpg_nino_mtavruli_normal.ttf');
  }
  
  @font-face {
    font-family: 'BPG Nino Medium';
    src: url('./assets/bpg_nino_medium.ttf');
  }
  
  body {
    margin: 0;
    font-family: 'BPG Nino Mtavruli';
    color:#10202B;
  }
  @import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500&display=swap');


  