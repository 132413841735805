.header-container{
    background-color: #10202b;
    width: 100%;
    height: 10%;
    font-size: 21px;
    color:white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 100px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}
.header-container ul {
 display: flex;


}
.header-container ul li{
   list-style: none;
   margin-left: 60px;
  
}

.header-left{
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px;
}
.link{
    text-decoration: none;
    color: white;
}

.underline{
    background-color: #DAA161;
    width: 50px;
    height: 4px;
    border-radius: 20px;
    margin-top: 7px;
}
.link-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.noUnderline{
    background-color: #daa26100;
    width: 50px;
    height: 4px;
    border-radius: 20px;
    margin-top: 7px;
    
}
.switch-language:hover{
    cursor: pointer;

}
.switch-language{
    font-size: 25px;
    line-height: 20px;
    word-spacing: 2px;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.burgermenu{
    width: 30px;
    height: auto;
    display: none;
}
/* ------------------------- Responsive part ----------------- */

@media screen and (max-width: 1300px) {
    .header-container{
        background-color: #10202b;
        height: 9%;
        font-size: 19px;
        color:white;
        padding: 10px 30px 10px 100px;
        
    
    }
    .p3en_cap{
        font-size: 20px !important;
    }
    
   
    
    .switch-language{
        font-size: 20px;
        line-height: 20px;
        word-spacing: 2px;
        letter-spacing: 0px;
    }
  }

  @media screen and (max-width: 1000px) {
    .header-container{
        background-color: #10202b;
        height: 9%;
        font-size: 19px;
        color:white;
        padding: 10px 30px 10px 80px;
        
    
    }
   
     .header-container ul li{
        list-style: none;
        margin-left: 30px;
     }
  }

  @media screen and (max-width: 900px) {
    .header-container{
        background-color: #10202b;
        height: 9%;
        font-size: 19px;
        color:white;
        padding: 10px 30px 10px 70px;
        
    
    }
    .burgermenu{
        display: flex;
    }
    .header-container ul {
       flex-direction: column;
       position: absolute;
       top:70%;
       right: 0;
       display: none ;
       }
       .header-container ul li{
          padding: 10px 30px;
          background-color: #10202b;
          text-align: center;
          font-size: 20px;
          margin-top: -5px;
       }
  }
  @media screen and (max-width: 500px){
    .name1{
        margin-left: 0px !important;
    }
    .header-container{
       
        padding: 10px 30px 10px 30px;
       
    
    }
  }
  @media screen and (max-width: 300px) {
    
    .header-container ul li {
        font-size: 15px;
       }
    .name1{
        font-size: 16px;
    }   
  }
  @media screen and (min-width: 900px) {
    
    .header-container ul {
        display: flex !important;

       }
      
  }
