.service-container{
   
    
}
.services-list{
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 15%;
   
  
}
.services-list p{
    cursor: pointer;

    text-align: center;
}
.services-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   
   
}
.img-container{
     margin-top: 50px;
     cursor:pointer;
      
     border:1px solid #10202B;
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 80px;
     height: 80px;
     margin-bottom: 10px;
}

.current-service{
    background-color: #DAA161;
 
}

.services_list_container{
    margin-top: 50px;
 
}
.services_list_container li{
  width: 50%;
  margin-left: 3%;
  font-family: 'BPG Nino Medium';
  margin-top: 20px;
}



.p11en_cap{
    font-size: 19px !important;
    text-transform: uppercase;

}
.p22en{
    font-size: 21px;
    line-height: 20px;
}
.whiteTowers2{
    bottom: 0;
    right: 0;
    width: 400px !important;
    height: auto;
    margin-right: 40px;
 
}
.right-service-container{
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 290px);
}
.current-service-container{
    width: 100%;
}
/* ----------------  Responsive -------------- */


@media screen and (max-width: 1400px) {
    .services-container{
        flex-direction: row;
        flex-wrap: wrap;
       
    }
    .services-list{
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 25%;
       margin-top: -20px;
      
}
    .whiteTowers2{
        bottom: 0;
        right: 0;
        width: 380px !important;
        height: auto;
        margin-bottom: 20px;
        margin-right: 40px;
        align-self: flex-end;
    }
    .service-container{
        margin-bottom: 7%;
    
    }
    .current-service-container{
        margin-bottom: 100px;
    }
    .services_list_container li{
        width: 90%;
        margin-top: 20px;
      }
}

@media screen and (max-width: 1200px) {
    .services-container{
        flex-direction: row;
        flex-wrap: wrap;
       
    }
    .services-list{
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 25%;

}
    .current-service-container{
        margin-bottom: 70px;
    }
    .whiteTowers2{
        bottom: 0 !important;
        right: 0;
        width: 360px !important;
        height: auto;
        margin-right: 40px;
    }
   
    .service-container{
   margin-bottom: 100px;
    
    }
    
}

@media screen and (max-width: 1000px) {
    .services-container{
        flex-direction: row;
        flex-wrap: wrap;
       
    }
    .img-container img{
        width: 30px !important;
        height: auto;
    }
    .img-container{
        width: 70px;
     height: 70px;
    }
    .services-list{
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 25%;

}
.current-service-container{
    margin-bottom: 70px;
    width: 90% !important;
}
.right-service-container{
flex-direction: column;

}
    .whiteTowers2{
        bottom: 0 !important;
        right: 0;
        width: 360px !important;
        height: auto;
        margin-right: 40px;
    }
    .service-container{
   margin-bottom: 100px;
    
    }
    .services-list p{
        font-size: 16px !important;
        width: 100%;
        text-align: center;
    }
    
}
@media screen and (max-width: 500px) {
    .services-list{
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 35%;
       background-color: red;


}
.img-container{
    width: 60px;
 height: 60px;
}
.img-container img{
    width: 30px !important;
    height: auto;
    
}

.whiteTowers2{
    bottom: 0;
    right: 0;
    width: 300px !important;
    height: auto;
    margin-bottom: 20px;
    margin-left: -40px;
    align-self: flex-end;
    background-color: red;
   
}

}