.contact-inner-container{
    padding: 50px 50px 100px 70px;
    display: flex;
    justify-content: space-between;
    font-size: 21px;
    
}

.infos{
    display: flex;
    justify-content: space-between;
 
   
  
}
.infos p:nth-child(1){
    margin-right: 8px;
    font-weight: 700;
    width: 40%;
    font-family: 'BPG Nino Medium';  
  
}

.infos p:nth-child(2){
    margin-right: 8px;
    width: 80%;
    font-family: 'BPG Nino Medium';  
   
}

.companyInformation{
    margin-top: 50px;
    margin-right: 10px;

}
.contact-right{
    height: auto;
    width: 68vw;
    height: 71vh;
}
.contact-left{
    width: 25%;
}

/* -------------------- Responsive Part --------------- */
@media screen and (max-width: 1700px){
   
    
    .companyInformation{
        margin-top: 50px;
        padding-right: 0;
        padding-right: 20px;
        
    }

    .contact-left{
        width: 35%;
    }
    .infos p:nth-child(1){
        margin-right: 8px;
        font-weight: 700;
        width: 30% !important; 
       
      
    }
    
    .infos p:nth-child(2){
        margin-right: 8px;
        
        width: 80% !important;  
     
    }
    .p2en{
       font-size: 20px;
    }
    .p3en{
        font-size: 22px !important;
    }
}

@media screen and (max-width: 1575px){
   
    
    .companyInformation{
        margin-top: 50px;
        
    }
    .contact-right{
        width: 60vw;
    }
    .contact-left{
        width: 40%;
    }
    .infos p:nth-child(1){
        margin-right: 8px;
        font-weight: 700;
        width: 30%; 
        
    }
    
    .infos p:nth-child(2){
        margin-right: 8px;
        width: 60%;  
    }

}

@media screen and (max-width: 1360px){
   
    
    .companyInformation{
        margin-top: 50px;
    }

    .contact-left{
        width: 40%;
    }
    .infos p:nth-child(1){
        margin-right: 8px;
        font-weight: 700;
        width: 35% !important; 
      
    }
    
    .infos p:nth-child(2){
        margin-right: 8px;
        width: 65% !important;  
        
    }

    
}





@media screen and (max-width: 1150px){
   
    
    .companyInformation{
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .contact-right{
        width: 75vw;
        height: 75vh;
        margin-bottom: 100px;
    }
    .contact-left{
        width: 60%;
    }
    .infos p:nth-child(1){
        margin-right: 8px;
        font-weight: 700;
        width: 30% !important;
        
    }
    
    .infos p:nth-child(2){
        margin-right: 8px;
        width: 70%; 
       
    }
    .contact-inner-container{
        padding: 50px 50px 0px 70px;
        display: flex;
        justify-content: center;
        font-size: 21px;
        align-items: center;
        flex-direction: column;
    }
    
}


@media screen and (max-width: 850px){
   
    
    .companyInformation{
        margin-top: 50px;
        margin-bottom: 100px;
        width: 100%;
    }
    .contact-right{
        width: 100%;
        height: 75vh;
        margin-bottom: 100px;
    }
    .contact-left{
        width: 100%;
    }
    .infos p:nth-child(1){
        margin-right: 8px;
        font-weight: 700;
        width: 35%!important;
    }
    
    .infos p:nth-child(2){
        margin-right: 8px;
        width: 65% !important;
       
    }
    .contact-inner-container{
        padding: 50px 50px 0px 70px;
        display: flex;
        justify-content: center;
        font-size: 21px;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
    }
    
}